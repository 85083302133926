import {
  AppHeaderItem,
  AppHeaderLinkItem,
  AppHeaderSimpleDropdownItem,
  FormattedAppHeaderItems,
  FormattedMobileAppHeaderItems,
} from '../AppHeader/shared';
import { GlobalHeaderProps } from '.';
import {
  addPayment,
  bootcampCta,
  businessSolutions,
  careerCenter,
  catalogDropdown,
  communityDropdown,
  contactSales,
  enterpriseLogo,
  freeProfile,
  loading,
  login,
  logo,
  myHome,
  myPercipioHome,
  pricingDropdown,
  proProfile,
  resourcesDropdown,
  signUp,
  simpleResourcesDropdown,
  tryProForFree,
  tryRiskFree,
  unpausePro,
  upgradePlan,
  upgradeToPro,
} from './GlobalHeaderItems';
import { ResourceDropdownFormattedLabel, User } from './types';

const transformSimpleResourcesDropdownlabels = (
  resourcesLabel: ResourceDropdownFormattedLabel | undefined
): AppHeaderSimpleDropdownItem => ({
  ...simpleResourcesDropdown,
  text: resourcesLabel?.title || simpleResourcesDropdown.text,
  popover: simpleResourcesDropdown.popover.map((item: AppHeaderLinkItem) => ({
    ...item,
    text:
      resourcesLabel?.[item.id as keyof ResourceDropdownFormattedLabel] ||
      item.text,
  })),
});

const anonHeaderItems = (
  renderLogin: boolean,
  renderSignUp: boolean,
  hidePricing?: boolean,
  user?: User,
  siteSearchUpgrade?: boolean
): FormattedAppHeaderItems => {
  const specialLogo = { ...logo, checkMini: siteSearchUpgrade };
  const leftItems: AppHeaderItem[] = [
    specialLogo,
    catalogDropdown(user?.hideCareerPaths),
    resourcesDropdown(),
    communityDropdown(),
    ...(hidePricing ? [] : [pricingDropdown]),
    careerCenter,
    businessSolutions,
  ];

  const rightItems: AppHeaderItem[] = [];
  if (renderLogin) {
    rightItems.push(login);
  }
  if (renderSignUp) {
    rightItems.push(signUp);
  }

  return {
    left: leftItems,
    right: rightItems,
  };
};

const anonMobileHeaderItems = (
  renderLogin: boolean,
  renderSignUp: boolean,
  hidePricing?: boolean,
  user?: User,
  siteSearchUpgrade?: boolean
): FormattedMobileAppHeaderItems => {
  const specialLogo = { ...logo, checkMini: siteSearchUpgrade };
  const leftItems: AppHeaderItem[] = [specialLogo];

  const rightItems: AppHeaderItem[] = [];
  if (renderLogin) {
    rightItems.push(login);
  }
  if (renderSignUp) {
    rightItems.push(signUp);
  }

  const mainMenuItems: AppHeaderItem[] = [
    catalogDropdown(user?.hideCareerPaths),
    resourcesDropdown(),
    communityDropdown(),
    ...(hidePricing ? [] : [pricingDropdown]),
    careerCenter,
    businessSolutions,
  ];

  return {
    left: leftItems,
    right: rightItems,
    mainMenu: mainMenuItems,
  };
};

export const anonDefaultHeaderItems = (
  hidePricing?: boolean,
  user?: User
): FormattedAppHeaderItems => {
  return anonHeaderItems(true, true, hidePricing, user);
};

export const anonDefaultMobileHeaderItems = (
  hidePricing?: boolean,
  user?: User,
  siteSearchUpgrade?: boolean
): FormattedMobileAppHeaderItems => {
  return anonMobileHeaderItems(
    true,
    true,
    hidePricing,
    user,
    siteSearchUpgrade
  );
};

export const anonLandingHeaderItems = (
  hidePricing?: boolean,
  user?: User
): FormattedAppHeaderItems => {
  return anonHeaderItems(true, false, hidePricing, user);
};

export const anonLandingMobileHeaderItems = (
  hidePricing?: boolean,
  user?: User,
  siteSearchUpgrade?: boolean
): FormattedMobileAppHeaderItems => {
  return anonMobileHeaderItems(
    true,
    false,
    hidePricing,
    user,
    siteSearchUpgrade
  );
};

export const anonLoginHeaderItems = (
  hidePricing?: boolean,
  user?: User
): FormattedAppHeaderItems => {
  return anonHeaderItems(false, true, hidePricing, user);
};

export const anonLoginMobileHeaderItems = (
  hidePricing?: boolean,
  user?: User,
  siteSearchUpgrade?: boolean
): FormattedMobileAppHeaderItems => {
  return anonMobileHeaderItems(
    false,
    true,
    hidePricing,
    user,
    siteSearchUpgrade
  );
};

export const anonSignupHeaderItems = (
  hidePricing?: boolean,
  user?: User
): FormattedAppHeaderItems => {
  return anonHeaderItems(true, false, hidePricing, user);
};

export const anonSignupMobileHeaderItems = (
  hidePricing?: boolean,
  user?: User,
  siteSearchUpgrade?: boolean
): FormattedMobileAppHeaderItems => {
  return anonMobileHeaderItems(
    true,
    false,
    hidePricing,
    user,
    siteSearchUpgrade
  );
};

export const freeHeaderItems = (
  user: User,
  hidePricing?: boolean,
  showMoneyback?: boolean
): FormattedAppHeaderItems => {
  const specialLogo = { ...logo, checkMini: true };

  const leftItems: AppHeaderItem[] = [
    specialLogo,
    myHome,
    catalogDropdown(user?.hideCareerPaths),
    resourcesDropdown(),
    communityDropdown(),
    ...(hidePricing ? [] : [pricingDropdown]),
    careerCenter,
    businessSolutions,
  ];
  const upgradeButton = showMoneyback
    ? tryRiskFree(user.proCheckoutUrl)
    : upgradeToPro(user.proCheckoutUrl);

  const rightItems: AppHeaderItem[] = [];
  rightItems.push(freeProfile(user));
  rightItems.push(
    user.showProUpgrade ? upgradeButton : tryProForFree(user.proCheckoutUrl)
  );

  return {
    left: leftItems,
    right: rightItems,
  };
};

export const freeMobileHeaderItems = (
  user: User,
  hidePricing?: boolean,
  showMoneyback?: boolean
): FormattedMobileAppHeaderItems => {
  const specialLogo = { ...logo, checkMini: true };
  const leftItems: AppHeaderItem[] = [specialLogo];
  const rightItems: AppHeaderItem[] = [];

  const upgradeButton = showMoneyback
    ? tryRiskFree(user.proCheckoutUrl)
    : upgradeToPro(user.proCheckoutUrl);

  const mainMenuItems: AppHeaderItem[] = [
    myHome,
    catalogDropdown(user?.hideCareerPaths),
    resourcesDropdown(),
    communityDropdown(),
    ...(hidePricing ? [] : [pricingDropdown]),
    careerCenter,
    businessSolutions,
    freeProfile(user, true),
    user.showProUpgrade ? upgradeButton : tryProForFree(user.proCheckoutUrl),
  ];

  return {
    left: leftItems,
    right: rightItems,
    mainMenu: mainMenuItems,
  };
};

export const enterpriseHeaderItems = (user: User, props: GlobalHeaderProps) => {
  const {
    hideHeaderLinksInChromeless,
    hideHeaderResourcesDropdown,
    localizedLabels,
  } = props;
  const leftItems: AppHeaderItem[] = hideHeaderLinksInChromeless
    ? [enterpriseLogo(user, true)]
    : [
        enterpriseLogo(user, true),
        myPercipioHome(user, localizedLabels?.enterpriseFormattedHomeLabel),
      ];

  if (!hideHeaderResourcesDropdown)
    leftItems.push(
      transformSimpleResourcesDropdownlabels(localizedLabels?.resourcesDropdown)
    );
  return {
    left: leftItems,
    right: [],
  };
};

export const proHeaderItems = (user: User): FormattedAppHeaderItems => {
  const leftItems: AppHeaderItem[] = [
    logo,
    myHome,
    catalogDropdown(user?.hideCareerPaths),
    resourcesDropdown(),
    communityDropdown(),
    ...(user.isPlusUser ? [upgradePlan] : []),
    careerCenter,
    businessSolutions,
  ];

  const rightItems: AppHeaderItem[] = [];
  rightItems.push(proProfile(user));
  if (user.isPaused) {
    rightItems.push(unpausePro);
  }

  return {
    left: leftItems,
    right: rightItems,
  };
};

export const teamsHeaderItems = (
  user: User,
  teamsRevampEnabled?: boolean
): FormattedAppHeaderItems => {
  const leftItems: AppHeaderItem[] = [
    logo,
    myHome,
    catalogDropdown(user?.hideCareerPaths),
    resourcesDropdown(),
    communityDropdown(),
    careerCenter,
  ];

  const rightItems: AppHeaderItem[] = [];
  if (teamsRevampEnabled && user.isBusinessUser) {
    rightItems.push(contactSales);
  }
  if (teamsRevampEnabled && user.isAccountManager && user.inBusinessTrial) {
    rightItems.push(addPayment);
  }

  rightItems.push(proProfile(user));

  return {
    left: leftItems,
    right: rightItems,
  };
};

export const enterpriseMobileHeaderItems = (
  user: User,
  props: GlobalHeaderProps
): FormattedMobileAppHeaderItems => {
  const {
    hideHeaderLinksInChromeless,
    hideHeaderResourcesDropdown,
    localizedLabels,
  } = props;
  const leftItems: AppHeaderItem[] = [enterpriseLogo(user, true)];
  const rightItems: AppHeaderItem[] = [];

  const mainMenuItems: AppHeaderItem[] = hideHeaderLinksInChromeless
    ? []
    : [myPercipioHome(user, localizedLabels?.enterpriseFormattedHomeLabel)];

  if (!hideHeaderResourcesDropdown)
    mainMenuItems.push(
      transformSimpleResourcesDropdownlabels(localizedLabels?.resourcesDropdown)
    );
  return {
    left: leftItems,
    right: rightItems,
    mainMenu: mainMenuItems,
  };
};

export const proMobileHeaderItems = (
  user: User
): FormattedMobileAppHeaderItems => {
  const leftItems: AppHeaderItem[] = [logo];
  const rightItems: AppHeaderItem[] = [];

  const mainMenuItems: AppHeaderItem[] = [
    myHome,
    catalogDropdown(user?.hideCareerPaths),
    resourcesDropdown(),
    communityDropdown(),
    ...(user.isPlusUser ? [upgradePlan] : []),
    careerCenter,
    businessSolutions,
    proProfile(user),
  ];

  if (user.isPaused) {
    mainMenuItems.push(unpausePro);
  }

  return {
    left: leftItems,
    right: rightItems,
    mainMenu: mainMenuItems,
  };
};

export const teamsMobileHeaderItems = (
  user: User,
  teamsRevampEnabled?: boolean
): FormattedMobileAppHeaderItems => {
  const leftItems: AppHeaderItem[] = [logo];
  const rightItems: AppHeaderItem[] = [];

  const mainMenuItems: AppHeaderItem[] = [
    myHome,
    catalogDropdown(user?.hideCareerPaths),
    resourcesDropdown(),
    communityDropdown(),
    careerCenter,
    proProfile(user),
  ];

  if (teamsRevampEnabled && user.isAccountManager && user.inBusinessTrial) {
    mainMenuItems.push(addPayment);
  }

  if (teamsRevampEnabled && user.isBusinessUser) {
    mainMenuItems.push(contactSales);
  }

  return {
    left: leftItems,
    right: rightItems,
    mainMenu: mainMenuItems,
  };
};

export const loadingHeaderItems: FormattedAppHeaderItems = {
  left: [loading],
  right: [],
};

export const loadingMobileHeaderItems: FormattedMobileAppHeaderItems = {
  left: [loading],
  right: [],
  mainMenu: [],
};

export const simpleHeaderItems: FormattedAppHeaderItems = {
  left: [logo],
  right: [],
};

export const simpleMobileHeaderItems: FormattedMobileAppHeaderItems = {
  left: [logo],
  right: [],
  mainMenu: [],
};

export const simpleBootcampHeaderItems = (
  bootcampSlug: string
): FormattedAppHeaderItems => {
  return { left: [logo], right: [bootcampCta(bootcampSlug)] };
};

export const simpleBootcampMobileHeaderItems = (
  bootcampSlug: string
): FormattedMobileAppHeaderItems => {
  return {
    left: [logo],
    right: [bootcampCta(bootcampSlug)],
    mainMenu: [],
  };
};
