import { Shimmer, Text } from '@codecademy/gamut';
import {
  AccountingCoinsIcon,
  ArrowThickCircleUpIcon,
  ArticleIcon,
  BookFlipPageIcon,
  BriefcaseIcon,
  CalendarIcon,
  CommunityIcon,
  DiscordOutlineIcon,
  EarthIcon,
  ForumsChatIcon,
  GearIcon,
  HouseEntranceIcon,
  MiniArrowRightIcon,
  NotebookIcon,
  OfficeBuildingDoubleIcon,
  PersonIcon,
  PersonPonytailIcon,
  PieLineGraphIcon,
  SupportIcon,
} from '@codecademy/gamut-icons';

import {
  AppHeaderCatalogDropdownItem,
  AppHeaderFillButtonItem,
  AppHeaderLinkItem,
  AppHeaderLogoItem,
  AppHeaderProfileDropdownItem,
  AppHeaderRenderElementItem,
  AppHeaderResourcesDropdownItem,
  AppHeaderSimpleDropdownItem,
  AppHeaderTextButtonItem,
} from '../AppHeader/shared';
import { User } from './types';
import { formatEnterpriseUrl } from './urlHelpers';

export const loading: AppHeaderRenderElementItem = {
  id: 'header-loading',
  type: 'render-element',
  renderElement: () => (
    <>
      <Shimmer aria-hidden height={36} width={128} />
      <Text screenreader>Loading menu bar</Text>
    </>
  ),
};

export const logo: AppHeaderLogoItem = {
  dataTestId: 'header-logo',
  id: 'logo',
  href: '/',
  enterprise: false,
  pro: false,
  trackingTarget: 'topnav_logo',
  type: 'logo',
};

export const enterpriseLogo = (
  user: User,
  removeAnchorLink?: boolean
): AppHeaderLogoItem => {
  return {
    dataTestId: 'header-logo',
    id: 'logo',
    href:
      formatEnterpriseUrl(user?.enterpriseUrl) ??
      'https://www.skillsoft.com/login-skillsoft',
    enterprise: true,
    pro: false,
    trackingTarget: 'topnav_enterprise_logo',
    type: 'logo',
    removeAnchorLink,
  };
};

export const myHome: AppHeaderLinkItem = {
  dataTestId: 'header-home',
  icon: HouseEntranceIcon,
  id: 'my-home',
  text: 'My Home',
  href: '/learn',
  trackingTarget: 'topnav_home',
  type: 'link',
};

export const myPercipioHome = (
  user: User,
  customTitle?: string
): AppHeaderLinkItem => {
  return {
    dataTestId: 'header-percihome',
    icon: HouseEntranceIcon,
    id: 'my-home',
    text: customTitle || 'Home',
    href:
      formatEnterpriseUrl(user?.enterpriseUrl) ??
      'https://www.skillsoft.com/login-skillsoft',
    newTab: true,
    trackingTarget: 'topnav_enterprise_home',
    type: 'link',
  };
};

export const catalogDropdown = (
  hideCareerPaths?: boolean
): AppHeaderCatalogDropdownItem => ({
  dataTestId: 'header-catalog',
  icon: BookFlipPageIcon,
  id: 'catalog-dropdown',
  text: 'Catalog',
  trackingTarget: 'topnav_catalog',
  type: 'catalog-dropdown',
  hideCareerPaths,
});

export const resourcesDropdown = (): AppHeaderResourcesDropdownItem => ({
  dataTestId: 'header-resources',
  icon: NotebookIcon,
  id: 'resources-dropdown',
  text: 'Resources',
  trackingTarget: 'topnav_resources',
  type: 'resources-dropdown',
});

export const simpleResourcesDropdown: AppHeaderSimpleDropdownItem = {
  dataTestId: 'header-resources',
  icon: NotebookIcon,
  id: 'resources-dropdown',
  text: 'Resources',
  popover: [
    {
      id: 'articles',
      href: '/articles',
      trackingTarget: 'topnav_enterprise_articles',
      text: 'Articles',
      type: 'link',
    },
    {
      id: 'docs',
      href: '/resources/docs',
      trackingTarget: 'topnav_enterprise_docs',
      text: 'Docs',
      type: 'link',
    },
    {
      id: 'workspaces',
      href: '/users/me/workspaces',
      trackingTarget: 'topnav_enterprise_workspaces',
      text: 'Workspaces',
      type: 'link',
    },
  ],
  trackingTarget: 'topnav_resources',
  type: 'dropdown',
};

export const communityDropdown = (): AppHeaderSimpleDropdownItem => ({
  icon: CommunityIcon,
  id: 'community',
  text: 'Community',
  popover: [
    {
      id: 'forums',
      href: 'https://discuss.codecademy.com/',
      trackingTarget: 'topnav_community_forums',
      newTab: true,
      text: 'Forums',
      type: 'link',
      icon: ForumsChatIcon,
    },
    {
      id: 'chat',
      href: 'https://discord.com/invite/codecademy',
      newTab: true,
      trackingTarget: 'topnav_chat',
      text: 'Discord',
      type: 'link',
      icon: DiscordOutlineIcon,
    },
    {
      id: 'clubs',
      href: 'https://community.codecademy.com/c/start-here/',
      newTab: true,
      trackingTarget: 'topnav_community_clubs',
      text: 'Clubs',
      type: 'link',
      icon: EarthIcon,
    },
    {
      id: 'events',
      href: '/events',
      trackingTarget: 'topnav_community_events',
      text: 'Events',
      type: 'link',
      icon: CalendarIcon,
    },
    {
      id: 'learner-stories',
      href: '/resources/blog/category/learner-stories/',
      trackingTarget: 'topnav_learner_stories_hub',
      text: 'Learner Stories',
      type: 'link',
      icon: ArticleIcon,
    },
    {
      id: 'code-crew',
      href: 'https://try.codecademy.com/code-crew/',
      newTab: true,
      trackingTarget: 'topnav_code_crew',
      text: 'Code Crew',
      type: 'link',
      icon: PersonPonytailIcon,
    },
  ],
  trackingTarget: 'topnav_community',
  type: 'dropdown',
});

export const pricingDropdown: AppHeaderSimpleDropdownItem = {
  icon: AccountingCoinsIcon,
  id: 'pricing',
  text: 'Pricing',
  popover: [
    {
      id: 'pro-membership',
      href: '/pricing',
      trackingTarget: 'topnav_pro_membership',
      text: 'For Individuals',
      type: 'link',
    },
    {
      id: 'for-students',
      href: '/student-center',
      trackingTarget: 'topnav_pricing_students',
      text: 'For Students',
      type: 'link',
    },
    {
      id: 'for-teams',
      href: '/business#section-pricing-table',
      trackingTarget: 'topnav_pricing_business',
      text: 'For Business',
      type: 'link',
    },
  ],
  trackingTarget: 'topnav_pricing',
  type: 'dropdown',
};

export const upgradePlan: AppHeaderLinkItem = {
  icon: ArrowThickCircleUpIcon,
  id: 'upgrade-plan',
  trackingTarget: 'topnav_upgrade_plan',
  text: 'Upgrade',
  href: '/pricing',
  type: 'link',
};

export const businessSolutions: AppHeaderLinkItem = {
  icon: OfficeBuildingDoubleIcon,
  id: 'business-solutions',
  trackingTarget: 'topnav_business',
  text: 'For Business',
  href: '/business',
  type: 'link',
};

export const careerCenter: AppHeaderLinkItem = {
  icon: BriefcaseIcon,
  id: 'career-center',
  trackingTarget: 'topnav_career_center',
  text: 'Career Center',
  href: '/career-center',
  type: 'link',
};

const profileMyProfile: AppHeaderLinkItem = {
  id: 'my-profile',
  icon: PersonIcon,
  href: '/profiles/me',
  trackingTarget: 'avatar_my_profile',
  text: 'Profile',
  type: 'link',
};

const profileAccount: AppHeaderLinkItem = {
  id: 'account',
  icon: GearIcon,
  href: '/account',
  trackingTarget: 'avatar_settings',
  text: 'Account + Billing',
  type: 'link',
};

const profileMyHome: AppHeaderLinkItem = {
  id: 'my-home',
  icon: HouseEntranceIcon,
  href: '/learn',
  trackingTarget: 'avatar_dashboard',
  text: 'My Home',
  type: 'link',
};

const profileBusinessAccount: AppHeaderLinkItem = {
  id: 'business',
  icon: PieLineGraphIcon,
  href: '/business/plans',
  trackingTarget: 'avatar_business',
  text: 'Business Account Management',
  type: 'link',
};

const profileHelpCenter: AppHeaderLinkItem = {
  id: 'help-center',
  icon: SupportIcon,
  href: '/help',
  newTab: true,
  trackingTarget: 'avatar_help',
  text: 'Help Center',
  type: 'link',
};

const profileAdmin: AppHeaderLinkItem = {
  id: 'admin',
  dataTestId: 'admin-link',
  href: '/admin',
  trackingTarget: 'avatar_admin',
  text: 'Admin',
  type: 'link',
};

const profileCustomerSupport: AppHeaderLinkItem = {
  id: 'customer-support',
  href: '/admin/concessions',
  trackingTarget: 'avatar_customer_support',
  text: 'Customer Support',
  type: 'link',
};

const profileReportBug: AppHeaderLinkItem = {
  id: 'report-bug',
  href: 'https://codecademy.atlassian.net/servicedesk/customer/portal/9',
  newTab: true,
  trackingTarget: 'avatar_report_bug',
  text: 'Report a Bug [ADMIN]',
  type: 'link',
};

const profileLogOut: AppHeaderLinkItem = {
  id: 'log-out',
  href: '/sign_out',
  trackingTarget: 'avatar_log_out',
  text: 'Log Out',
  type: 'link',
};

export const freeProfile = (
  user: User,
  isMobile?: boolean
): AppHeaderProfileDropdownItem => {
  const topSection = [profileMyProfile];

  if (user?.isBusinessAdmin || !user?.isBusinessSsoUser) {
    topSection.push(profileAccount);
  }

  topSection.push(profileMyHome);

  if (!isMobile && user?.isAccountManager && !user?.hideBusinessAccount) {
    topSection.push(profileBusinessAccount);
  }
  topSection.push(profileHelpCenter);

  const bottomSection = [profileLogOut];

  const popover = [topSection, bottomSection];

  return {
    avatar: user.avatar,
    userDisplayName: user.displayName,
    id: 'profile',
    text: 'Profile',
    popover,
    trackingTarget: 'topnav_profile',
    type: 'profile-dropdown',
  };
};

export const proProfile = (user: User): AppHeaderProfileDropdownItem => {
  const topSection = [profileMyProfile];

  if (user.isBusinessAdmin || !user.isBusinessSsoUser) {
    topSection.push(profileAccount);
  }

  topSection.push(profileMyHome);

  if (
    (user?.isAccountManager || user?.isBusinessAdmin) &&
    !user?.hideBusinessAccount
  ) {
    topSection.push(profileBusinessAccount);
  }

  topSection.push(profileHelpCenter);

  const middleSection: AppHeaderLinkItem[] = [];

  if (user.isAdmin) {
    middleSection.push(profileAdmin);
  }
  if (user.isCustomerSupport) {
    middleSection.push(profileCustomerSupport);
  }
  if (user.isAdmin) {
    middleSection.push(profileReportBug);
  }

  const bottomSection = [profileLogOut];

  const popover = [topSection, middleSection, bottomSection];

  return {
    avatar: user.avatar,
    userDisplayName: user.displayName,
    id: 'profile',
    text: 'Profile',
    popover,
    trackingTarget: 'topnav_profile',
    type: 'profile-dropdown',
  };
};

export const contactSales: AppHeaderTextButtonItem = {
  id: 'contact-sales',
  href: 'mailto:enterprise@codecademy.com',
  text: 'Contact sales',
  trackingTarget: 'topnav_contact_sales',
  type: 'text-button',
};

export const addPayment: AppHeaderFillButtonItem = {
  dataTestId: 'add-payment-link',
  id: 'add-payment',
  text: 'Add payment',
  href: '/business/checkout?plan_type=upgrade',
  trackingTarget: 'topnav_add_payment',
  type: 'fill-button',
};

export const tryProForFree = (
  checkoutUrl?: string
): AppHeaderFillButtonItem => ({
  dataTestId: 'upgrade-link',
  id: 'try-pro',
  text: 'Start free trial',
  href: checkoutUrl || '/pages/pro',
  trackingTarget: 'topnav_pro_trial',
  type: 'fill-button',
});

export const upgradeToPro = (
  checkoutUrl?: string
): AppHeaderFillButtonItem => ({
  dataTestId: 'upgrade-link',
  id: 'upgrade-to-pro',
  text: 'Upgrade plan',
  href: checkoutUrl || '/pages/pro',
  trackingTarget: 'topnav_pro_upgrade',
  type: 'fill-button',
});

export const tryRiskFree = (checkoutUrl?: string): AppHeaderFillButtonItem => ({
  dataTestId: 'upgrade-link',
  id: 'try-risk-free',
  text: 'Try risk-free',
  href: checkoutUrl || '/pages/pro',
  trackingTarget: 'topnav_pro_upgrade',
  type: 'fill-button',
});

export const unpausePro: AppHeaderFillButtonItem = {
  dataTestId: 'unpause-link',
  id: 'unpause-pro',
  text: 'Unpause Now',
  href: '/account/billing',
  trackingTarget: 'topnav_pro_unpause',
  type: 'fill-button',
};

export const login: AppHeaderTextButtonItem = {
  dataTestId: 'header-sign-in',
  id: 'login',
  text: 'Log In',
  href: '/login',
  trackingTarget: 'topnav_login',
  type: 'text-button',
  redirect: true,
};

export const signUp: AppHeaderFillButtonItem = {
  dataTestId: 'header-sign-up',
  id: 'signup',
  text: 'Sign Up',
  href: '/register',
  trackingTarget: 'topnav_signup',
  type: 'fill-button',
  redirect: true,
};

export const bootcampCta = (bootcampSlug: string): AppHeaderFillButtonItem => ({
  dataTestId: 'bootcamp-header-cta',
  id: 'bootcamp-header-cta',
  text: 'Register Now',
  href: `/pay/bootcamp/${bootcampSlug}`,
  trackingTarget: 'topnav_bootcamp_cta',
  type: 'fill-button',
  variant: 'secondary',
  icon: MiniArrowRightIcon,
  iconPosition: 'right',
});
